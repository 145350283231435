import React from 'react';
import Layout from '../../components/layout';
import { Grid } from '@material-ui/core';
import SectionContainerLayoutWithFilter from '../../components/section-container-layout-with-filter';
import CardImageTitleDescription from '../../components/card-image-title-description/card-image-title-description';
import SEO from '../../components/seo';

const DevelopmentGoalsPage = () => {
  const DEVELOPMENT_GOALS = [
    {
      image: '1-no-poverty',
      title: '1. no poverty',
      body: 'End Poverty In All Its Forms Everywhere',
    },
    {
      image: '2-zero-hunger',
      title: '2. zero hunger',
      body: 'End Hunger, Achieve Food Security And Improved Nutrition And Promote Sustainable Agriculture',
    },
    {
      image: '3-good-health-and-well-being',
      title: '3. good health and well being',
      body: 'Ensure Healthy Lives And Promote Well-Being For All At All Ages',
    },
    {
      image: '4-quality-education',
      title: '4. quality education',
      body: 'Ensure Inclusive And Equitable Quality Education And Promote Lifelong Learning Opportunities For All',
    },
    {
      image: '5-gender-quality',
      title: '5. gender quality',
      body: 'Achieve Gender Equality And Empower All Women And Girls',
    },
    {
      image: '6-clean-water-and-sanitation',
      title: '6. clean water and sanitation',
      body: 'Ensure Availability And Sustainable Management Of Water And Sanitation For All',
    },
    {
      image: '7-affordable-and-clean-energy',
      title: '7. affordable and clean energy',
      body: 'Ensure Access To Affordable, Reliable, Sustainable And Modern Energy For All',
    },
    {
      image: '8-decent-work-and-economic-growth',
      title: '8. decent work and economic growth',
      body:
        'Promote Sustained, Inclusive And Sustainable Economic Growth, Full And Productive Employment And Decent Work For All',
    },
    {
      image: '9-industry-innovation-and-infrastructure',
      title: '9. industry innovation and infrastructure',
      body: 'Build Resilient Infrastructure, Promote Inclusive And Sustainable Industrialization And Foster Innovation',
    },
    {
      image: '10-reduced-inequalities',
      title: '10. reduced inequalities',
      body: 'Reduce Inequality Within And Among Countries',
    },
    {
      image: '11-sustainable-cities-and-communities',
      title: '11. sustainable cities and communities',
      body: 'Make Cities And Human Settlements Inclusive, Safe, Resilient And Sustainable',
    },
    {
      image: '12-responsible-consumption-and-production',
      title: '12. responsible consumption and production',
      body: 'Ensure sustainable consumption and production patterns',
    },
    {
      image: '13-climate-action',
      title: '13. climate action',
      body: 'Take Urgent Action To Combat Climate Change And Its Impacts',
    },
    {
      image: '14-life-below-water',
      title: '14. life below water',
      body: 'Conserve And Sustainably Use The Oceans, Seas And Marine Resources For Sustainable Development',
    },
    {
      image: '15-life-on-land',
      title: '15. life on land',
      body:
        'Protect, restore and promote sustainable use of terrestrial ecosystems, sustainably manage forests, combat desertification, and halt and reverse land degradation and halt biodiversity loss',
    },
    {
      image: '16-peace-justice-and-strong-institutions',
      title: '16. peace justice and strong institutions',
      body:
        'Promote Peaceful And Inclusive Societies For Sustainable Development, Provide Access To Justice For All And Build Effective, Accountable And Inclusive Institutions At All Levels',
    },
    {
      image: '17-partnership-for-the-goals',
      title: '17. partnership for the goals',
      body: 'Strengthen The Means Of Implementation And Revitalize The Global Partnership For Sustainable Development',
    },
  ];

  return (
    <Layout>
      <SEO lang='en' title='UN SDG’s | Global Manufacturing & Industrialisation Summit' />
      <SectionContainerLayoutWithFilter title='UN SUSTAINABLE DEVELOPMENT GOALS'>
        <Grid container direction='row' spacing={3}>
          {DEVELOPMENT_GOALS.map((goal, index) => (
            <Grid item xs={12} sm={6} md={3} key={index}>
              <CardImageTitleDescription
                image={goal.image}
                title={`${goal.title}`}
                body={goal.body}
                disabelRippleEffect
                disableCursor
              />
            </Grid>
          ))}
        </Grid>
      </SectionContainerLayoutWithFilter>
    </Layout>
  );
};

export default DevelopmentGoalsPage;
